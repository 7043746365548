import React, { useEffect, useMemo, useState, useRef, createRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import qs from 'qs';
// Editor
import toast from 'react-hot-toast';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, FormControlLabel, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox } from '@material-ui/core';
// components
import Page from '../components/Page';

const EditUsers = () => {
  const navigate = useNavigate();
  const query = qs.parse(document.location.search, {ignoreQueryPrefix: true});
  const [checked, setChecked] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [log, setLog] = useState({});
  const [codetxt, setCodetxt] = useState('');
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    axi.post(`/admin/history/purchaselog/${query.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setLog(res.data.data);
        setCodetxt(res.data.data.code.map(e=>e + '\n').join('').trim());
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
  },[]);

  const onChange = (e) => {
    setLog({
        ...log,
        [e.target.name]: e.target.value
    });
  };

  const onCodeChange = (e) => {
    setCodetxt(e.target.value);
  }

  const onSubmit = async (e) => {
    toast.error('이미 판매된 제품의 정보는 수정이 불가능합니다.');
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '92ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      width: '25ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));
  const classes = useStyles();

  return (
    <Page title="HASHA Admin | Editing User">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            판매 기록 열람
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            판매 기록 열람
          </Typography>
          
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="uid" value={`${log.uid}`} required onChange={onChange} label="유저 ID" variant="outlined" />
            <TextField id="outlined-basic" name="categoryName" value={`${log.categoryName}`} required onChange={onChange} label="카테고리명" variant="outlined" />
            <TextField id="outlined-basic" name="productName" value={`${log.productName}`} required onChange={onChange} label="제품명" variant="outlined" />
            <TextField id="outlined-basic" name="priceOptName" value={`${log.priceOptName}`} required onChange={onChange} label="구매 옵션" variant="outlined" />
            <TextField id="outlined-basic" name="amount" value={`${log.amount}`} required onChange={onChange} label="금액" variant="outlined" />
            <TextField id="outlined-basic" name="quantity" value={`${log.quantity}`} required onChange={onChange} label="수량" variant="outlined" />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" value={codetxt} onChange={onCodeChange} required multiline label="코드 목록" variant="outlined" />
          </form>
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
          disabled
        >
        수정불가
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

export default EditUsers;

import { useEffect, useCallback, useState, useRef, createRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
// Editor
import toast from 'react-hot-toast';
import Prism from 'prismjs';
import videoPlugin from '@leeonfield/editor-plugin-video';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, FormControlLabel, Checkbox, Switch, CircularProgress } from '@material-ui/core';
// components
import Page from '../components/Page';
import { Gif } from '@material-ui/icons';

export default function AddProduct() {
  const navigate = useNavigate();
  const [categorys, setCategorys] = useState([{}]);
  const [Category, setCategory] = useState('');
  const [Name, setName] = useState('');
  const [Priority, setPriority] = useState(1);
  const [ImgUrl, setImgUrl] = useState('');
  const [open, setOpen] = useState(false);
  const [Opts, setOpts] = useState({
    Opt1: '',
    Opt2: '',
    Opt3: '',
    Opt4: '',
  });

  const [MinAmount, setMinAmount] = useState({
    minA0: 1,
    minA1: 1,
    minA2: 1,
    minA3: 1,
    minA4: 1,
    minA5: 1,
    minA6: 1,
    minA7: 1,
    minA8: 1,
    minA9: 1,
    minA10: 1,
    minA11: 1,
    minA12: 1,
    minA13: 1,
    minA14: 1,
    minA15: 1,
    minA16: 1,
    minA17: 1,
    minA18: 1,
    minA19: 1,
    minA20: 1,
    minA21: 1,
    minA22: 1,
    minA23: 1,
    minA24: 1,
    minA25: 1,
    minA26: 1,
    minA27: 1
  });

  const [GInputs, setGInputs] = useState({
    GInput0: null,
    GInput1: null,
    GInput2: null,
    GInput3: null,
    GInput4: null,
    GInput5: null,
    GInput6: null,
    GInput7: null,
    GInput8: null,
    GInput9: null,
    GInput10: null,
    GInput11: null,
    GInput12: null,
    GInput13: null,
    GInput14: null,
    GInput15: null,
    GInput16: null,
    GInput17: null,
    GInput18: null,
    GInput19: null,
    GInput20: null,
    GInput21: null,
    GInput22: null,
    GInput23: null,
    GInput24: null,
    GInput25: null,
    GInput26: null,
    GInput27: null,
  });
  
  const [Available, setAvailable] = useState({
    A1: true,
    A2: true,
    A3: true,
    A4: true,
  });
  const [productAvailable, setProductAvailable] = useState(true);

  useEffect(() => {
    axi.post('/admin/category/list',{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setCategorys(res.data.data);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  },[]);

  const {
    Opt1, Opt2, Opt3, Opt4
  } = Opts;
  const {
    A1, A2, A3, A4
  } = Available;
  const {
    GInput0, GInput1, GInput2, GInput3, GInput4, GInput5, GInput6, GInput7, GInput8, GInput9, GInput10, GInput11, GInput12, GInput13, GInput14, GInput15, GInput16, GInput17, GInput18, GInput19, GInput20, GInput21, GInput22, GInput23, GInput24, GInput25, GInput26, GInput27
  } = GInputs;

  const {
    minA0, minA1, minA2, minA3, minA4, minA5, minA6, minA7, minA8, minA9, minA10, minA11, minA12, minA13, minA14, minA15, minA16, minA17, minA18, minA19, minA20, minA21, minA22, minA23, minA24, minA25, minA26, minA27
  } = MinAmount;

  const editorRef = createRef();

  const onGInputChange = (e) => {
    setGInputs({
      ...GInputs,
      [e.target.name]: e.target.value
    });
  };
  const onMinAmountChange = (e) => {
    setMinAmount({
      ...MinAmount,
      [e.target.name]: e.target.value
    });
  };
  const onOptChange = (e) => {
    setOpts({
      ...Opts,
      [e.target.name]: e.target.value
    });
  }
  const onAvailableChange = (e) => {
    setAvailable({
      ...Available,
      [e.target.name]: e.target.checked
    });
  }
  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const onNameHandler = (event) => {
    setName(event.currentTarget.value);
  };
  const onPriorityHandler = (event) => {
    setPriority(event.currentTarget.value);
  };
  const onImgUrlHandler = (event) => {
    setImgUrl(event.currentTarget.value);
  };
  const onSwitchChange = (event) => {
    setProductAvailable(event.currentTarget.checked);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onSubmit = async (e) => {
    try{
      const content = editorRef.current.getInstance().getMarkdown();
      console.log(GInputs)
      if ( Category=='' || Name=='' ) {
        toast.error("모든 입력 란을 채워주세요.");
        return;
      } else {
        axi.post(`/admin/product/add`,{
          name: Name, 
          description: content, 
          thumbnail: ImgUrl,
          sequence: Priority,
          priceopts: Object.values(Opts),
          prices: Object.values(GInputs),
          minAmount: Object.values(MinAmount),
          available: Object.values(Available),
          productAvailable: productAvailable,
          category: Category
        },{headers:{Authorization : `Bearer ${getAccessToken()}`}})
        .then((res)=>{
          if(res.data.code === 200){
            toast.success("적용 완료!");
          }
          if(res.data.code === 401){
            toast.error(res.data.msg);
            navigate('/auth/login',{ replace:true });
          }
          else{
            toast.error(res.data.msg);
          }
        }).catch((err)=>{
          console.log(err);
        });
      }
    } catch (err) {
      console.log(err);
      toast.error("알 수 없는 오류입니다. 올바르게 입력하고 다시 시도해주세요.");
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '30ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      width: '25ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));
  const classes = useStyles();

  return (
    <Page title="제품 추가 | HASHA">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            제품 추가 중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            제품 속성
          </Typography>
          <FormControl className={classes.root}>
            <InputLabel id="demo-controlled-open-select-label">카테고리</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={Category}
              onChange={handleChange}
              required
            >
              {categorys.map((row)=>{
                return <MenuItem value={row._id}>{row.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" value={Name} required onChange={onNameHandler} label="제품 이름" variant="outlined" />
            <TextField id="outlined-basic" value={Priority} required onChange={onPriorityHandler} label="정렬 우선 순위 (1~999)" variant="outlined" />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" value={ImgUrl} onChange={onImgUrlHandler} label="제품 이미지 URL" variant="outlined" />
            <FormControlLabel
              control={<Switch name="available" checked={productAvailable} onChange={onSwitchChange}/>}
              label="제품 활성화 여부"
            />
          </form>
          <Typography variant="h5" gutterBottom>
            제품 옵션 이름
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="Opt1" value={Opt1} onChange={onOptChange} label="옵션 1 이름" required variant="outlined" />
            <FormControlLabel
              control={
              <Checkbox
                name="A1"
                checked={A1}
                onChange={onAvailableChange}
              />
              }
              label={`${Opt1?(Opt1):"옵션 1"} 활성화 여부`}
              labelPlacement="end"
            />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="Opt2" value={Opt2} onChange={onOptChange} label="옵션 2 이름" required variant="outlined" />
            <FormControlLabel
              control={
              <Checkbox
                name="A2"
                checked={A2}
                onChange={onAvailableChange}
              />
              }
              label={`${Opt2?(Opt2):"옵션 2"} 활성화 여부`}
              labelPlacement="end"
            />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="Opt3" value={Opt3} onChange={onOptChange} label="옵션 3 이름" required variant="outlined" />
            <FormControlLabel
              control={
              <Checkbox
                name="A3"
                checked={A3}
                onChange={onAvailableChange}
              />
              }
              label={`${Opt3?(Opt3):"옵션 3"} 활성화 여부`}
              labelPlacement="end"
            />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="Opt4" value={Opt4} onChange={onOptChange} label="옵션 4 이름" required variant="outlined" />
            <FormControlLabel
              control={
              <Checkbox
                name="A4"
                checked={A4}
                onChange={onAvailableChange}
              />
              }
              label={`${Opt4?(Opt4):"옵션 4"} 활성화 여부`}
              labelPlacement="end"
            />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
               {`${Opt1?(Opt1):"옵션 1"}`} 가격 설정
            </Typography>
            <TextField id="outlined-basic" name="GInput0" value={GInput0} onChange={onGInputChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - 비구매자 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput1" value={GInput1} onChange={onGInputChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - 구매자 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput2" value={GInput2} onChange={onGInputChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - VIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput3" value={GInput3} onChange={onGInputChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - VVIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput4" value={GInput4} onChange={onGInputChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - RVIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput5" value={GInput5} onChange={onGInputChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - 리셀러 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput6" value={GInput6} onChange={onGInputChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - 직셀러 가격`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
               {`${Opt2?(Opt2):"옵션 2"}`} 가격 설정
            </Typography>
            <TextField id="outlined-basic" name="GInput7" value={GInput7} onChange={onGInputChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - 비구매자 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput8" value={GInput8} onChange={onGInputChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - 구매자 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput9" value={GInput9} onChange={onGInputChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - VIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput10" value={GInput10} onChange={onGInputChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - VVIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput11" value={GInput11} onChange={onGInputChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - RVIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput12" value={GInput12} onChange={onGInputChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - 리셀러 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput13" value={GInput13} onChange={onGInputChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - 직셀러 가격`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
               {`${Opt3?(Opt1):"옵션 3"}`} 가격 설정
            </Typography>
            <TextField id="outlined-basic" name="GInput14" value={GInput14} onChange={onGInputChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - 비구매자 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput15" value={GInput15} onChange={onGInputChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - 구매자 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput16" value={GInput16} onChange={onGInputChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - VIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput17" value={GInput17} onChange={onGInputChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - VVIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput18" value={GInput18} onChange={onGInputChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - RVIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput19" value={GInput19} onChange={onGInputChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - 리셀러 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput20" value={GInput20} onChange={onGInputChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - 직셀러 가격`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
               {`${Opt4?(Opt4):"옵션 4"}`} 가격 설정
            </Typography>
            <TextField id="outlined-basic" name="GInput21" value={GInput21} onChange={onGInputChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - 비구매자 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput22" value={GInput22} onChange={onGInputChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - 구매자 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput23" value={GInput23} onChange={onGInputChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - VIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput24" value={GInput24} onChange={onGInputChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - VVIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput25" value={GInput25} onChange={onGInputChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - RVIP 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput26" value={GInput26} onChange={onGInputChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - 리셀러 가격`} required variant="outlined" />
            <TextField id="outlined-basic" name="GInput27" value={GInput27} onChange={onGInputChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - 직셀러 가격`} required variant="outlined" />
          </form>

          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
               {`${Opt1?(Opt1):"옵션 1"}`} 최소 구매 수량 설정
            </Typography>
            <TextField id="outlined-basic" name="minA0" value={minA0} onChange={onMinAmountChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - 비구매자 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA1" value={minA1} onChange={onMinAmountChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - 구매자 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA2" value={minA2} onChange={onMinAmountChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - VIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA3" value={minA3} onChange={onMinAmountChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - VVIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA4" value={minA4} onChange={onMinAmountChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - RVIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA5" value={minA5} onChange={onMinAmountChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - 리셀러 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA6" value={minA6} onChange={onMinAmountChange} type="number" label = {`${Opt1?(Opt1):"옵션 1"} - 직셀러 최소 구매 수량`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
                {`${Opt2?(Opt2):"옵션 2"}`} 최소 구매 수량 설정
            </Typography>
            <TextField id="outlined-basic" name="minA7" value={minA7} onChange={onMinAmountChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - 비구매자 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA8" value={minA8} onChange={onMinAmountChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - 구매자 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA9" value={minA9} onChange={onMinAmountChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - VIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA10" value={minA10} onChange={onMinAmountChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - VVIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA11" value={minA11} onChange={onMinAmountChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - RVIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA12" value={minA12} onChange={onMinAmountChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - 리셀러 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA13" value={minA13} onChange={onMinAmountChange} type="number" label = {`${Opt2?(Opt2):"옵션 2"} - 직셀러 최소 구매 수량`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
                {`${Opt3?(Opt3):"옵션 3"}`} 최소 구매 수량 설정
            </Typography>
            <TextField id="outlined-basic" name="minA14" value={minA14} onChange={onMinAmountChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - 비구매자 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA15" value={minA15} onChange={onMinAmountChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - 구매자 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA16" value={minA16} onChange={onMinAmountChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - VIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA17" value={minA17} onChange={onMinAmountChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - VVIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA18" value={minA18} onChange={onMinAmountChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - RVIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA19" value={minA19} onChange={onMinAmountChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - 리셀러 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA20" value={minA20} onChange={onMinAmountChange} type="number" label = {`${Opt3?(Opt3):"옵션 3"} - 직셀러 최소 구매 수량`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
                {`${Opt4?(Opt4):"옵션 4"}`} 최소 구매 수량 설정
            </Typography>
            <TextField id="outlined-basic" name="minA21" value={minA21} onChange={onMinAmountChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - 비구매자 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA22" value={minA22} onChange={onMinAmountChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - 구매자 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA23" value={minA23} onChange={onMinAmountChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - VIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA24" value={minA24} onChange={onMinAmountChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - VVIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA25" value={minA25} onChange={onMinAmountChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - RVIP 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA26" value={minA26} onChange={onMinAmountChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - 리셀러 최소 구매 수량`} required variant="outlined" />
            <TextField id="outlined-basic" name="minA27" value={minA27} onChange={onMinAmountChange} type="number" label = {`${Opt4?(Opt4):"옵션 4"} - 직셀러 최소 구매 수량`} required variant="outlined" />
          </form>
          
          <Typography variant="h5" gutterBottom>
            제품 세부 설명
          </Typography>
          <Editor
            initialEditType="wysiwyg"
            previewStyle='vertical'
            plugins={[videoPlugin], [colorSyntax, [codeSyntaxHighlight, { highlighter: Prism }]]}
            ref={editorRef}
          />
        <br />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
          등록하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

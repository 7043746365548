import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { prolong, registerWithInfo } from '../../../utils/auth';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    code: Yup.string()
      .required('라이센스 코드를 입력해주세요'),
    group: Yup.string()
    .min(3, '너무 짧습니다')
    .max(20, '너무 깁니다')
    .required('상점 ID를 입력해주세요.'),
  });

  const formik = useFormik({
    initialValues: {
      code: '',
      group: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      if(await prolong({ ...values })) navigate('/auth/login', { replace: true });
    }
  });

  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="라이센스"
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />
          <Tooltip arrow title="알파벳과 숫자만 입력 가능, 3자 ~ 20자">
            <TextField
              fullWidth
              label="상점 ID"
              {...getFieldProps('group')}
              error={Boolean(touched.group && errors.group)}
              helperText={touched.group && errors.group}
            />
          </Tooltip>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            연장하기
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

import { useEffect, useCallback, useState, useRef, createRef } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
// material
import { Grid, Button, Container, Stack, Typography, TextField } from '@material-ui/core';
// components
import Page from '../components/Page';
import { makeStyles } from '@material-ui/styles';

export default function AddCategory() {
  const navigate = useNavigate();
  const useStyles = makeStyles((theme) => ({
  root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    }
  }));
const classes = useStyles();
  const [name, setName] = useState('');
  const [sequence, setSequence] = useState('');
  const [loading, setLoading] = useState(false);
  const onSubmit = () => {
    setLoading(true);
    axi.post('/admin/category/add',{name:name, sequence:sequence},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  }
  return (
    <Page title="카테고리 추가 | HASHA">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            카테고리 추가 중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="dGrade" value={`${name}`} required onChange={e => {setName(e.target.value)}} label="카테고리 이름" variant="outlined"/>
            <TextField id="outlined-basic" name="cGrade" value={`${sequence}`} required onChange={e => {setSequence(e.target.value)}} label="우선순위(숫자)" variant="outlined"/>
          </form>
        <br />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

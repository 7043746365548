import { useEffect, useState } from 'react';
// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import Page from '../components/Page';
import {
  AppWeeklyProfit,
  AppUserCount,
  AppMonthlyProfit,
  AppDailyProfit,
  AppUserGrade,
  AppWeeklyGraph,
  AppDailyNetProfit,
  AppMonthlyNetProfit,
  AppWeeklyNetProfit,
  AppTotalProfit
} from '../components/_dashboard/app';
import { getMyData, getLocalData } from '../utils/user';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'

export default function DashboardApp() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [amount, setAmount] = useState([]);
  const [surplus, setSurplus] = useState([]);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState(0);
  const [profit, setProfit] = useState([0,0,0,0,0,0,0]);
  const [netprofit, setNetProfit] = useState([0,0,0,0,0,0,0]);
  useEffect(() => {
    setData(getMyData());
    axi.post('/admin/statistics',{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setAmount(res.data.amounts);
        setSurplus(res.data.surplus);
        setUsers(res.data.users);
        setCount(res.data.usercount);
        setProfit(res.data.profit);
        setNetProfit(res.data.netprofit);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  },[])
  return (
    <Page title="Dashboard | HASHA">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">안녕하세요, {data.nick} 님.</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppDailyProfit data={amount[0]}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWeeklyProfit data={amount[1]}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppMonthlyProfit data={amount[2]}/>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppTotalProfit data={amount[3]}/>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

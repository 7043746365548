import React, { useEffect, useMemo, useState, useRef, createRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import qs from 'qs';
import toast from 'react-hot-toast';
import Prism from 'prismjs';
import videoPlugin from '@leeonfield/editor-plugin-video';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, FormControlLabel, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox } from '@material-ui/core';
// components
import Page from '../components/Page';

const EditNotice = () => {
  const navigate = useNavigate();
  const query = qs.parse(document.location.search, {ignoreQueryPrefix: true});
  const [open, setOpen] = React.useState(false);
  const [Secopen, setSecOpen] = React.useState(false);
  const [codegroup, setCodegroup] = useState({});
  const [product, setProduct] = useState([]);
  const [codetxt, setCodetxt] = useState('');
  const [loading, setLoading] = useState(false);
  console.log(codegroup)
  useEffect(() => {
    axi.post(`/admin/code/group/${query.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setCodegroup(res.data.data);
        setCodetxt(res.data.codes.map(e=>e.code + '\n').join('').trim());
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
    axi.post('/admin/product/onlylist',{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setProduct(res.data.data);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  },[]);

  const onChange = (e) => {
    setCodegroup({
        ...codegroup,
        [e.target.name]: e.target.value
    });
  };
  const onCodeChange = (e) => {
    setCodetxt(e.target.value);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleSecClose = () => {
    setSecOpen(false);
  };
  const handleSecOpen = () => {
    setSecOpen(true);
  };
  const onSubmit = async (e) => {
    setLoading(true);
    const keys = codetxt.split('\n');
    axi.post(`/admin/code/group/set/${query.oid}`,{group:codegroup, codes:keys},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '92ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      width: '25ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));
  const classes = useStyles();

  return (
    <Page title="HASHA Admin | Editing Notice">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            코드 그룹
          </Typography>
        </Stack>
        <Container maxWidth="xl">
            <Typography variant="h5" gutterBottom>
                코드그룹 수정 / 열람
            </Typography>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label">제품</InputLabel>
                <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                name="product"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                defaultValue={codegroup.product}
                value={codegroup.product?codegroup.product:''}
                onChange={onChange}
                required
                >
                {product?.map((data) => {
                    return (
                    <MenuItem value={data._id}>{data.name}</MenuItem>
                    );
                })}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-controlled-open-select-label">옵션선택</InputLabel>
                <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                name="priceOpt"
                open={Secopen}
                onClose={handleSecClose}
                onOpen={handleSecOpen}
                value={codegroup.priceOpt?codegroup.priceOpt:''}
                onChange={onChange}
                required
                >
                {product.length!=0 && codegroup.product?(
                    console.log(product, codegroup),
                    product.find(e => e._id == codegroup.product).priceOptions.map((data) => {
                    return (
                        <MenuItem value={data._id}>{data.optionName}</MenuItem>
                    );
                    })
                ):(
                    <MenuItem value="">제품을 먼저 선택해주세요</MenuItem>
                )}
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField id="outlined-basic" name="name" value={`${codegroup.name}`} required onChange={onChange} label="그룹 이름" variant="outlined" />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField id="outlined-basic" name="cost" value={`${codegroup.cost}`} required onChange={onChange} label="입고가" variant="outlined" />
            </FormControl>
            <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" value={codetxt} onChange={onCodeChange} required multiline label="코드 목록" variant="outlined" />
            </form>
            {/*{codes?.map((data) => {
                    return (
                    <FormControl className={classes.formControl}>
                        <TextField id="outlined-basic" name="name" value={`${data.code}`} required onChange={onChange} label="코드" variant="outlined" />
                    </FormControl>
                    )
                })}*/}
            <Button
            type="submit"
            size="large"
            variant="contained"
            onClick={onSubmit}
            >
            수정하기
            </Button>
        </Container>
      </Container>
    </Page>
  );
}

export default EditNotice;

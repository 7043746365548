import { Link as RouterLink } from 'react-router-dom';
import { useEffect } from 'react';
import { clearTokens } from '../utils/auth';
import { clearData } from '../utils/user'
// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Link, Container, Typography } from '@material-ui/core';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { ProlongForm } from '../components/authentication/prolong';
import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Register() {
    useEffect(() => {
        clearTokens();
        clearData();
    },[])    

    return (
        <RootStyle title="라이센스 연장 | HASHA">
        <AuthLayout>
            연장하셨나요? &nbsp;
            <Link underline="none" variant="subtitle2" component={RouterLink} to="/auth/login">
            로그인 하기
            </Link>
        </AuthLayout>

        <MHidden width="mdDown">
            <SectionStyle>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                HASHA와 함께, <br />놀라운 매출 상승률을< br/>경험해보세요.
            </Typography>
            <img alt="register" src="/static/illustrations/illustration_register.png" />
            </SectionStyle>
        </MHidden>

        <Container>
            <ContentStyle>
            <ProlongForm />
            <MHidden width="smUp">
                <Typography variant="subtitle2" sx={{ mt: 3, textAlign: 'center' }}>
                연장하셨나요?&nbsp;
                <Link to="/auth/login" component={RouterLink}>
                    로그인 하기
                </Link>
                </Typography>
            </MHidden>
            </ContentStyle>
        </Container>
        </RootStyle>
    );
}

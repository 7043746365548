import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

const productone = [...Array(6)].map((_, index) => ({
  id: faker.datatype.uuid(),
  categoryarr: (['카테고리1', '카테고리2', '카테고리3']),
  category: sample(['카테고리1', '카테고리2', '카테고리3']),
  name: sample(['양숙이뷰지이용권', '[인기] 김환우 쥬지', '[HOT!] 오레오', '노무현과함께하는봉하운지체험']),
  thumbnail: mockImgAvatar,
  priority: faker.random.number({ min: 1, max: 100 }),
  description: faker.lorem.paragraph(),
  available: faker.random.boolean(),
  priceOption: [{
    "optionName" : "1DAY",
    "gradePrice" : [ 
        {
            "grade" : "A",
            "price" : 10000
        }, 
        {
            "grade" : "B",
            "price" : 10000
        }, 
        {
            "grade" : "C",
            "price" : 10000
        }, 
        {
            "grade" : "D",
            "price" : 10000
        }, 
        {
            "grade" : "E",
            "price" : 10000
        }, 
        {
            "grade" : "F",
            "price" : 10000
        }
    ],
    "available" : true
},
{
    "optionName" : "7DAY",
    "gradePrice" : [ 
        {
            "grade" : "A",
            "price" : 50000
        }, 
        {
            "grade" : "B",
            "price" : 50000
        }, 
        {
            "grade" : "C",
            "price" : 50000
        }, 
        {
            "grade" : "D",
            "price" : 50000
        }, 
        {
            "grade" : "E",
            "price" : 150000
        }, 
        {
            "grade" : "F",
            "price" : 10000
        }
    ],
    "available" : true
},
{
  "optionName" : "14DAY",
  "gradePrice" : [ 
      {
          "grade" : "A",
          "price" : 50000
      }, 
      {
          "grade" : "B",
          "price" : 50000
      }, 
      {
          "grade" : "C",
          "price" : 50000
      }, 
      {
          "grade" : "D",
          "price" : 50000
      }, 
      {
          "grade" : "E",
          "price" : 150000
      }, 
      {
          "grade" : "F",
          "price" : 10000
      }
  ],
  "available" : true
},
{
  "optionName" : "DAYDAY",
  "gradePrice" : [ 
      {
          "grade" : "A",
          "price" : 50000
      }, 
      {
          "grade" : "B",
          "price" : 50000
      }, 
      {
          "grade" : "C",
          "price" : 50000
      }, 
      {
          "grade" : "D",
          "price" : 50000
      }, 
      {
          "grade" : "E",
          "price" : 150000
      }, 
      {
          "grade" : "F",
          "price" : 10000
      }
  ],
  "available" : true
},]
}));

export default productone;

import { useEffect, useCallback, useState, useRef, createRef } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
// material
import { MenuItem, Button, Container, Stack, Typography, TextField, InputLabel, Select, FormControl } from '@material-ui/core';
// components
import Page from '../components/Page';
import { makeStyles } from '@material-ui/styles';

export default function AddCategory() {
  const navigate = useNavigate();
  const useStyles = makeStyles((theme) => ({
  root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    }
  }));
const classes = useStyles();
  const [amount, setAmount] = useState(1);
  const [expiryDate, setExpiryDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [grade, setGrade] = useState('');
  const [giftType, setGiftType] = useState('');
  const [minGrade, setMinGrade] = useState('');
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [count, setCount] = useState(1);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (e) => {
    setMinGrade(e.target.value);
  }
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleChange2 = (e) => {
    setGiftType(e.target.value);
  }
  const onSubmit = () => {
    setLoading(true);
    axi.post('/admin/giftcode/generate',{giftType:giftType, minGrade:minGrade, amount:amount, expiryDate:expiryDate, count:count},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  }
  return (
    <Page title="기프트 코드 발급 | HASHA">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            기프트 코드 발급 중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <form className={classes.root} noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">최소 적용 가능 등급</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={minGrade}
                onChange={handleChange}
                required
              >
                <MenuItem value='A'>직셀러</MenuItem>
                <MenuItem value='B'>리셀러</MenuItem>
                <MenuItem value='R'>RVIP</MenuItem>
                <MenuItem value='C'>VVIP</MenuItem>
                <MenuItem value='D'>VIP</MenuItem>
                <MenuItem value='E'>구매자</MenuItem>
                <MenuItem value='F'>비구매자</MenuItem>
              </Select>
            </FormControl>
          </form>
          <FormControl className={classes.root}>
            <InputLabel id="demo-controlled-open-select-label">유형</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open2}
              onClose={handleClose2}
              onOpen={handleOpen2}
              value={giftType}
              onChange={handleChange2}
              required
            >
              <MenuItem value="cash">적립금</MenuItem>
              <MenuItem value="point">포인트</MenuItem>
            </Select>
          </FormControl>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="amount" value={`${amount}`} required onChange={e => {setAmount(e.target.value)}} label="금액" variant="outlined" type="number"/>
            <TextField id="outlined-basic" name="expiryDate" value={`${expiryDate}`} required onChange={e => {setExpiryDate(e.target.value)}} label="만료일 (N일 뒤)" variant="outlined"/>
            <TextField id="outlined-basic" name="count" value={`${count}`} required onChange={e => {setCount(e.target.value)}} label="발급 개수" variant="outlined" type="number"/>
          </form>
        <br />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        발급하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

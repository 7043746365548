import React, { useEffect, useMemo, useState, useRef, createRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import qs from 'qs';
// Editor
import toast from 'react-hot-toast';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, FormControlLabel, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox, Switch } from '@material-ui/core';
// components
import Page from '../components/Page';

import userone from '../_mocks_/userone';

const EditUsers = () => {
  const navigate = useNavigate();
  const query = qs.parse(document.location.search, {ignoreQueryPrefix: true});
  const [checked, setChecked] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [psum, setPsum] = useState('');
  
  useEffect(() => {
    axi.post(`/admin/user/${query.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setUser(res.data.data);
        if (res.data.data.totalPmount) {
          setUser(res.data.data);
        } else {
          setUser({...res.data.data, totalPmount: res.data.pSum[0].sum});
      }}
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
  },[]);

  const onChange = (e) => {
    setUser({
        ...user,
        [e.target.name]: e.target.value
    });
  };

  const onSwitchChange = (e) => {
    setUser({...user, [e.target.name]: e.target.checked});
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onSubmit = async (e) => {
    setLoading(true);
    axi.post(`/admin/user/set/${query.oid}`,{...user},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '92ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      width: '25ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));
  const classes = useStyles();

  return (
    <Page title="HASHA Admin | Editing User">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            유저
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            유저 수정 / 열람
          </Typography>
          
          <form className={classes.root} noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">유저등급</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                name="userType"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={`${user.userType}`}
                onChange={onChange}
                required
              >
                <MenuItem value={'F'}>비구매자</MenuItem>
                <MenuItem value={'E'}>구매자</MenuItem>
                <MenuItem value={'D'}>VIP</MenuItem>
                <MenuItem value={'C'}>VVIP</MenuItem>
                <MenuItem value={'R'}>RVIP</MenuItem>
                <MenuItem value={'B'}>리셀러</MenuItem>
                <MenuItem value={'A'}>직셀러</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
                control={<Switch name="verified" checked={user.verified} onChange={onSwitchChange} />}
                label="유저 인증 여부"
            />
            <TextField id="outlined-basic" name="id" value={`${user.id}`} required onChange={onChange} label="유저 ID" variant="outlined" />
            <TextField id="outlined-basic" name="name" value={`${user.name}`} required onChange={onChange} label="입금자명" variant="outlined" />
            <TextField id="outlined-basic" name="reviewToken" value={`${user.reviewToken}`} required onChange={onChange} label="리뷰 토큰" variant="outlined" />
            <TextField id="outlined-basic" name="cultureFailure" value={`${user.cultureFailure}`} required onChange={onChange} label="컬쳐랜드 실패 횟수" variant="outlined" />
            <TextField id="outlined-basic" name="cash" value={`${user.cash}`} required onChange={onChange} label="유저 보유 금액" variant="outlined" />
            <TextField id="outlined-basic" name="point" value={`${user.point}`} required onChange={onChange} label="유저 보유 포인트" variant="outlined" />
            <TextField id="outlined-basic" name="totalPmount" value={`${user.totalPmount}`} required onChange={onChange} label="누적 구매 금액" variant="outlined" />
            <TextField
              id="outlined-multiline-static"
              label="유저 메모 (관리자 열람용)"
              name="memo"
              value={`${user.memo}`}
              multiline
              rows={4}
              defaultValue=""
              variant="outlined"
              onChange={onChange}
            />

          </form>
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

export default EditUsers;

import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

SearchNotFound.propTypes = {
  searchQuery: PropTypes.string
};

export default function SearchNotFound({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        '{searchQuery}'와 관련된 항목을 찾을 수 없습니다.
      </Typography>
      <Typography variant="body2" align="center">
        &nbsp;
        <s>얼레 어디갔盧... 아마 운지를 해버렸을지도...</s>
      </Typography>
    </Paper>
  );
}

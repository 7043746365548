import React, { useEffect, useCallback, useState, useRef, createRef } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import qs from 'qs';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// Editor
import toast from 'react-hot-toast';
import Prism from 'prismjs';
import videoPlugin from '@leeonfield/editor-plugin-video';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, FormControlLabel, Switch, Backdrop, CircularProgress, Checkbox} from '@material-ui/core';
import Page from '../components/Page';

export default function EditProducts() {
  const navigate = useNavigate();
  const query = qs.parse(document.location.search, {ignoreQueryPrefix: true});
  const [open, setOpen] = React.useState(false);
  const [product, setProduct] = useState(undefined);
  const [categorys, setCategorys] = useState([{}]);

  useEffect(() => {
    axi.post(`/admin/product/${query.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setProduct(res.data.data);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
    axi.post('/admin/category/list',{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setCategorys(res.data.data);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  },[]);
  const editorRef = createRef();
  /* 핸들러들 */
  const onChange = (e) => {
    setProduct({
      ...product,
      [e.target.name] : e.target.value
    });
  }
  const onAvailableChange = (e) => {
    let array = product;
    array.priceOptions[e.target.name].available = e.target.checked;
    setProduct({
      ...product,
      array
    });
  }
  const onGInputChange1 = (e) => {
    let array = product;
    array.priceOptions[0].priceArray[e.target.name] = parseInt(e.target.value);
    setProduct({
      ...product,
      array
    });
  };
  const onGInputChange2 = (e) => {
    let array = product;
    array.priceOptions[1].priceArray[e.target.name] = parseInt(e.target.value);
    setProduct({
      ...product,
      array
    });
  };
  const onGInputChange3 = (e) => {
    let array = product;
    array.priceOptions[2].priceArray[e.target.name] = parseInt(e.target.value);
    setProduct({
      ...product,
      array
    });
  };
  const onGInputChange4 = (e) => {
    let array = product;
    array.priceOptions[3].priceArray[e.target.name] = parseInt(e.target.value);
    setProduct({
      ...product,
      array
    });
  };
  const onMinAmountChange1 = (e) => {
    let array = product;
    array.priceOptions[0].minAmount[e.target.name] = parseInt(e.target.value);
    setProduct({
      ...product,
      array
    });
  };
  const onMinAmountChange2 = (e) => {
    let array = product;
    array.priceOptions[1].minAmount[e.target.name] = parseInt(e.target.value);
    setProduct({
      ...product,
      array
    });
  };
  const onMinAmountChange3 = (e) => {
    let array = product;
    array.priceOptions[2].minAmount[e.target.name] = parseInt(e.target.value);
    setProduct({
      ...product,
      array
    });
  };
  const onMinAmountChange4 = (e) => {
    let array = product;
    array.priceOptions[3].minAmount[e.target.name] = parseInt(e.target.value);
    setProduct({
      ...product,
      array
    });
  };
  const onOptChange = (e) => {
    let array = product;
    array.priceOptions[e.target.name].optionName = e.target.value;
    setProduct({
      ...product,
      array
    });
  }
  const onSwitchChange = (e) => {
    setProduct({
      ...product,
      [e.target.name] : e.target.checked
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onSubmit = async (e) => {
    axi.post(`/admin/product/set/${query.oid}`,{...product, description: editorRef.current.getInstance().getMarkdown()},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));

  const classes = useStyles();

  return (
    <Page title="제품 수정 | HASHA">
      {product==undefined?(
      <Backdrop 
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>):(<Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            제품 수정 중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            제품 속성
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">카테고리</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              defaultValue={product.category}
              value={product.category}
              onChange={e => product.category = e.target.value}
              required
            >
              {categorys.map((row)=>{
                return <MenuItem value={row._id}>{row.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="name" value={product.name} required onChange={onChange} label="제품 이름" variant="outlined" />
            <TextField id="outlined-basic" name="sequence" value={product.sequence} required onChange={onChange} label="정렬 우선 순위 (1~999)" variant="outlined" />
            
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="thumbnail" value={product.thumbnail} onChange={onChange} label="제품 이미지 URL" variant="outlined" fullWidth/>
            <FormControlLabel
              control={<Switch name="available" checked={product.available} onChange={onSwitchChange}/>}
              label="제품 활성화 여부"
            />
          </form>
          <Typography variant="h5" gutterBottom>
            제품 옵션 이름
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="0" value={product.priceOptions[0].optionName} onChange={onOptChange} label="옵션 1 이름" required variant="outlined" />
            <FormControlLabel
              control={
              <Checkbox
                name="0"
                checked={product.priceOptions[0].available}
                onChange={onAvailableChange}
              />
              }
              label={`${product.priceOptions[0].optionName} 활성화 여부`}
              labelPlacement="end"
            />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="1" value={product.priceOptions[1].optionName} onChange={onOptChange} label="옵션 2 이름" required variant="outlined" />
            <FormControlLabel
              control={
              <Checkbox
                name="1"
                checked={product.priceOptions[1].available}
                onChange={onAvailableChange}
              />
              }
              label={`${product.priceOptions[1].optionName} 활성화 여부`}
              labelPlacement="end"
            />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="2" value={product.priceOptions[2].optionName} onChange={onOptChange} label="옵션 3 이름" required variant="outlined" />
            <FormControlLabel
              control={
              <Checkbox
                name="2"
                checked={product.priceOptions[2].available}
                onChange={onAvailableChange}
              />
              }
              label={`${product.priceOptions[2].optionName} 활성화 여부`}
              labelPlacement="end"
            />
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="3" value={product.priceOptions[3].optionName} onChange={onOptChange} label="옵션 4 이름" required variant="outlined" />
            <FormControlLabel
              control={
              <Checkbox
                name="3"
                checked={product.priceOptions[3].available}
                onChange={onAvailableChange}
              />
              }
              label={`${product.priceOptions[3].optionName} 활성화 여부`}
              labelPlacement="end"
            />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
               {`${product.priceOptions[0].optionName}`} 가격 설정
            </Typography>
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="0" value={`${product.priceOptions[0].priceArray[0]}`} onChange={onGInputChange1} type="number" label = {`${product.priceOptions[0].optionName} - 비구매자 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="1" value={`${product.priceOptions[0].priceArray[1]}`} onChange={onGInputChange1} type="number" label = {`${product.priceOptions[0].optionName} - 구매자 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="2" value={`${product.priceOptions[0].priceArray[2]}`} onChange={onGInputChange1} type="number" label = {`${product.priceOptions[0].optionName} - VIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="3" value={`${product.priceOptions[0].priceArray[3]}`} onChange={onGInputChange1} type="number" label = {`${product.priceOptions[0].optionName} - VVIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="4" value={`${product.priceOptions[0].priceArray[4]}`} onChange={onGInputChange1} type="number" label = {`${product.priceOptions[0].optionName} - RVIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="5" value={`${product.priceOptions[0].priceArray[5]}`} onChange={onGInputChange1} type="number" label = {`${product.priceOptions[0].optionName} - 리셀러 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="6" value={`${product.priceOptions[0].priceArray[6]}`} onChange={onGInputChange1} type="number" label = {`${product.priceOptions[0].optionName} - 직셀러 가격`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
              {`${product.priceOptions[1].optionName}`} 가격 설정
            </Typography>
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="0" value={`${product.priceOptions[1].priceArray[0]}`} onChange={onGInputChange2} type="number" label = {`${product.priceOptions[1].optionName} - 비구매자 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="1" value={`${product.priceOptions[1].priceArray[1]}`} onChange={onGInputChange2} type="number" label = {`${product.priceOptions[1].optionName} - 구매자 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="2" value={`${product.priceOptions[1].priceArray[2]}`} onChange={onGInputChange2} type="number" label = {`${product.priceOptions[1].optionName} - VIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="3" value={`${product.priceOptions[1].priceArray[3]}`} onChange={onGInputChange2} type="number" label = {`${product.priceOptions[1].optionName} - VVIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="4" value={`${product.priceOptions[1].priceArray[4]}`} onChange={onGInputChange2} type="number" label = {`${product.priceOptions[1].optionName} - RVIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="5" value={`${product.priceOptions[1].priceArray[5]}`} onChange={onGInputChange2} type="number" label = {`${product.priceOptions[1].optionName} - 리셀러 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="6" value={`${product.priceOptions[1].priceArray[6]}`} onChange={onGInputChange2} type="number" label = {`${product.priceOptions[1].optionName} - 직셀러 가격`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
              {`${product.priceOptions[2].optionName}`} 가격 설정
            </Typography>
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="0" value={`${product.priceOptions[2].priceArray[0]}`} onChange={onGInputChange3} type="number" label = {`${product.priceOptions[2].optionName} - 비구매자 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="1" value={`${product.priceOptions[2].priceArray[1]}`} onChange={onGInputChange3} type="number" label = {`${product.priceOptions[2].optionName} - 구매자 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="2" value={`${product.priceOptions[2].priceArray[2]}`} onChange={onGInputChange3} type="number" label = {`${product.priceOptions[2].optionName} - VIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="3" value={`${product.priceOptions[2].priceArray[3]}`} onChange={onGInputChange3} type="number" label = {`${product.priceOptions[2].optionName} - VVIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="4" value={`${product.priceOptions[2].priceArray[4]}`} onChange={onGInputChange3} type="number" label = {`${product.priceOptions[2].optionName} - RVIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="5" value={`${product.priceOptions[2].priceArray[5]}`} onChange={onGInputChange3} type="number" label = {`${product.priceOptions[2].optionName} - 리셀러 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="6" value={`${product.priceOptions[2].priceArray[6]}`} onChange={onGInputChange3} type="number" label = {`${product.priceOptions[2].optionName} - 직셀러 가격`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
              {`${product.priceOptions[3].optionName}`} 가격 설정
            </Typography>
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="0" value={`${product.priceOptions[3].priceArray[0]}`} onChange={onGInputChange4} type="number" label = {`${product.priceOptions[3].optionName} - 비구매자 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="1" value={`${product.priceOptions[3].priceArray[1]}`} onChange={onGInputChange4} type="number" label = {`${product.priceOptions[3].optionName} - 구매자 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="2" value={`${product.priceOptions[3].priceArray[2]}`} onChange={onGInputChange4} type="number" label = {`${product.priceOptions[3].optionName} - VIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="3" value={`${product.priceOptions[3].priceArray[3]}`} onChange={onGInputChange4} type="number" label = {`${product.priceOptions[3].optionName} - VVIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="4" value={`${product.priceOptions[3].priceArray[4]}`} onChange={onGInputChange4} type="number" label = {`${product.priceOptions[3].optionName} - RVIP 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="5" value={`${product.priceOptions[3].priceArray[5]}`} onChange={onGInputChange4} type="number" label = {`${product.priceOptions[3].optionName} - 리셀러 가격`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="6" value={`${product.priceOptions[3].priceArray[6]}`} onChange={onGInputChange4} type="number" label = {`${product.priceOptions[3].optionName} - 직셀러 가격`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
              {`${product.priceOptions[0].optionName}`} 최소 구매 수량 설정
            </Typography>
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="0" value={`${product.priceOptions[0].minAmount[0]}`} onChange={onMinAmountChange1} type="number" label = {`${product.priceOptions[0].optionName} - 비구매자 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="1" value={`${product.priceOptions[0].minAmount[1]}`} onChange={onMinAmountChange1} type="number" label = {`${product.priceOptions[0].optionName} - 구매자 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="2" value={`${product.priceOptions[0].minAmount[2]}`} onChange={onMinAmountChange1} type="number" label = {`${product.priceOptions[0].optionName} - VIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="3" value={`${product.priceOptions[0].minAmount[3]}`} onChange={onMinAmountChange1} type="number" label = {`${product.priceOptions[0].optionName} - VVIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="4" value={`${product.priceOptions[0].minAmount[4]}`} onChange={onMinAmountChange1} type="number" label = {`${product.priceOptions[0].optionName} - RVIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="5" value={`${product.priceOptions[0].minAmount[5]}`} onChange={onMinAmountChange1} type="number" label = {`${product.priceOptions[0].optionName} - 리셀러 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[0].available} id="outlined-basic" name="6" value={`${product.priceOptions[0].minAmount[6]}`} onChange={onMinAmountChange1} type="number" label = {`${product.priceOptions[0].optionName} - 직셀러 최소 구매 수량`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
              {`${product.priceOptions[1].optionName}`} 최소 구매 수량 설정
            </Typography>
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="0" value={`${product.priceOptions[1].minAmount[0]}`} onChange={onMinAmountChange2} type="number" label = {`${product.priceOptions[1].optionName} - 비구매자 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="1" value={`${product.priceOptions[1].minAmount[1]}`} onChange={onMinAmountChange2} type="number" label = {`${product.priceOptions[1].optionName} - 구매자 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="2" value={`${product.priceOptions[1].minAmount[2]}`} onChange={onMinAmountChange2} type="number" label = {`${product.priceOptions[1].optionName} - VIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="3" value={`${product.priceOptions[1].minAmount[3]}`} onChange={onMinAmountChange2} type="number" label = {`${product.priceOptions[1].optionName} - VVIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="4" value={`${product.priceOptions[1].minAmount[4]}`} onChange={onMinAmountChange2} type="number" label = {`${product.priceOptions[1].optionName} - RVIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="5" value={`${product.priceOptions[1].minAmount[5]}`} onChange={onMinAmountChange2} type="number" label = {`${product.priceOptions[1].optionName} - 리셀러 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[1].available} id="outlined-basic" name="6" value={`${product.priceOptions[1].minAmount[6]}`} onChange={onMinAmountChange2} type="number" label = {`${product.priceOptions[1].optionName} - 직셀러 최소 구매 수량`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
              {`${product.priceOptions[2].optionName}`} 최소 구매 수량 설정
            </Typography>
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="0" value={`${product.priceOptions[2].minAmount[0]}`} onChange={onMinAmountChange3} type="number" label = {`${product.priceOptions[2].optionName} - 비구매자 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="1" value={`${product.priceOptions[2].minAmount[1]}`} onChange={onMinAmountChange3} type="number" label = {`${product.priceOptions[2].optionName} - 구매자 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="2" value={`${product.priceOptions[2].minAmount[2]}`} onChange={onMinAmountChange3} type="number" label = {`${product.priceOptions[2].optionName} - VIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="3" value={`${product.priceOptions[2].minAmount[3]}`} onChange={onMinAmountChange3} type="number" label = {`${product.priceOptions[2].optionName} - VVIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="4" value={`${product.priceOptions[2].minAmount[4]}`} onChange={onMinAmountChange3} type="number" label = {`${product.priceOptions[2].optionName} - RVIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="5" value={`${product.priceOptions[2].minAmount[5]}`} onChange={onMinAmountChange3} type="number" label = {`${product.priceOptions[2].optionName} - 리셀러 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[2].available} id="outlined-basic" name="6" value={`${product.priceOptions[2].minAmount[6]}`} onChange={onMinAmountChange3} type="number" label = {`${product.priceOptions[2].optionName} - 직셀러 최소 구매 수량`} required variant="outlined" />
          </form>
          <form className={classes.rootOpt} noValidate autoComplete="off">
            <Typography variant="h5" gutterBottom>
              {`${product.priceOptions[3].optionName}`} 최소 구매 수량 설정
            </Typography>
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="0" value={`${product.priceOptions[3].minAmount[0]}`} onChange={onMinAmountChange4} type="number" label = {`${product.priceOptions[3].optionName} - 비구매자 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="1" value={`${product.priceOptions[3].minAmount[1]}`} onChange={onMinAmountChange4} type="number" label = {`${product.priceOptions[3].optionName} - 구매자 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="2" value={`${product.priceOptions[3].minAmount[2]}`} onChange={onMinAmountChange4} type="number" label = {`${product.priceOptions[3].optionName} - VIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="3" value={`${product.priceOptions[3].minAmount[3]}`} onChange={onMinAmountChange4} type="number" label = {`${product.priceOptions[3].optionName} - VVIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="4" value={`${product.priceOptions[3].minAmount[4]}`} onChange={onMinAmountChange4} type="number" label = {`${product.priceOptions[3].optionName} - RVIP 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="5" value={`${product.priceOptions[3].minAmount[5]}`} onChange={onMinAmountChange4} type="number" label = {`${product.priceOptions[3].optionName} - 리셀러 최소 구매 수량`} required variant="outlined" />
            <TextField disabled={!product.priceOptions[3].available} id="outlined-basic" name="6" value={`${product.priceOptions[3].minAmount[6]}`} onChange={onMinAmountChange4} type="number" label = {`${product.priceOptions[3].optionName} - 직셀러 최소 구매 수량`} required variant="outlined" />
          </form>
          <Typography variant="h5" gutterBottom>
            제품 세부 설명
          </Typography>
          <Editor
            initialValue={`${product.description}`}
            initialEditType="wysiwyg"
            previewStyle='vertical'
            plugins={[videoPlugin], [colorSyntax, [codeSyntaxHighlight, { highlighter: Prism }]]}
            ref={editorRef}
          />
        <br />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
          등록하기
        </Button>
        </Container>
      </Container>)}
    </Page>
  );
}

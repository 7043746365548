import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast';
import { getAccessToken } from './auth'
import { Link as useNavigate } from 'react-router-dom';


//disable cors
export const axi = axios.create({
    baseURL: "https://kr3.hasha.in/api",
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }
});


export const errMessages = {
  "BAD_REQUEST" : "올바르지 않은 접근입니다.",
  "UNAUTHORIZED" : "로그인 후 이용해주세요.",
  "TOKEN_ERROR" : "올바르지 않은 접근입니다.",
  "EXPIRED_TOKEN" : "세션이 만료되었습니다. 다시 로그인해주세요.",
  "UNKNOWN_TOKEN_ERROR" : "알 수 없는 오류입니다. 다시 로그인해주세요.",
  "CLIENT_NOT_FOUND" : "올바른 주소로 접속해 주십시오.",
  "FILTERED" : "올바르지 않은 입력입니다",
  "NO_DOCS" : "데이터를 찾을 수 없습니다.",
  "ID_INAPPR" : "아이디를 찾을 수 없습니다.",
  "PW_INAPPR" : "패스워드를 찾을 수 없습니다.",
  "GROUP_EXIST" : "이미 존재하는 상점ID입니다.",
  "USER_EXIST" : "이미 존재하는 유저입니다.",
  "NO_CLIENT" : "존재하지 않는 상점ID입니다.",
  "NO_LICENSE" : "라이센스가 존재하지 않습니다.",
  "DB" : "데이터베이스 오류입니다. 관리자에게 문의해주세요.",
  "HASH_ERROR" : "해싱 과정에서 오류가 발생하였습니다. 관리자에게 문의해주세요.",
  "TOO_LARGE" : "수량이 너무 많습니다.",
  "WEBHOOK_ERROR_A" : "등록하신 디스코드 어드민 웹훅에 문제가 있습니다.",
  "WEBHOOK_ERROR_G" : "등록하신 디스코드 웹훅에 문제가 있습니다.",
};

import faker from 'faker';
import { sample } from 'lodash';
// utils
import { mockImgAvatar } from '../utils/mockImages';

// ----------------------------------------------------------------------

const userone = {
  id: faker.datatype.uuid(),
  username: 'sampleuser',
  userType: sample(['비구매자', '구매자', 'VIP', 'VVIP', 'SVIP']),
  verified: sample(['No', 'Yes']),
  cash: sample([0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]),
  point: sample([0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]),
  totalCharge: sample([0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]),
  totalPurchase: sample([0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]),
  reviewToken: sample([0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]),
  joinDate: faker.date.past(),
  loginDate: faker.date.past(),
  memo: faker.lorem.paragraph(),
  cultureFailure: sample([0, 1]),
  virgin: sample([true, false]),
  verified: sample([true, false]),
  recent: sample(["2021-05-23", "2021-08-13", "2021-07-21", "2021-06-29", "2021-08-02", "2021-05-23", "2021-05-18"])
  
};
export default userone;

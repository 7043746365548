import { Icon } from '@iconify/react';
import dollarCircleFilled from '@iconify/icons-ant-design/dollar-circle-filled';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
// utils
import { fNumber } from '../../../utils/formatNumber';

import { useEffect, useState } from 'react';

import { axi } from '../../../utils/api';

import { toast } from 'react-hot-toast';

import { getAccessToken } from 'src/utils/auth';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.success.darker,
  backgroundColor: theme.palette.success.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.success.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.success.dark, 0)} 0%, ${alpha(
    theme.palette.success.dark,
    0.24
  )} 100%)`
}));

export default function AppUserCount(data) {

  const [count, setCount] = useState(null);
  useEffect(() => {
    axi.get('/admin/ads/ongoingCount', { headers: { Authorization: `Bearer ${getAccessToken()}` } })
    .then((res) => {
      setCount(res.data.data);
    })
    .catch((err) => {
      toast.error('광고 수를 불러오는데 실패했습니다.');
      console.log(err);
    })
  }
  ,[])

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={dollarCircleFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{fNumber(count*1200000)+"원" || "불러오는중"}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        예상 월 광고액
      </Typography>
    </RootStyle>
  );
}

import React, { useEffect, useMemo, useState, useRef, createRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import qs from 'qs';
import toast from 'react-hot-toast';
import Prism from 'prismjs';
import videoPlugin from '@leeonfield/editor-plugin-video';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, FormControlLabel, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox } from '@material-ui/core';
// components
import Page from '../components/Page';

const Editlink = () => {
  const navigate = useNavigate();
  const query = qs.parse(document.location.search, {ignoreQueryPrefix: true});
  const [link, setLink] = useState({});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  
  useEffect(() => {
    axi.post(`/admin/link/${query.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setLink(res.data.data);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
  },[]);

  const onChange = (e) => {
    setLink({
        ...link,
        [e.target.name]: e.target.value
    });
  };

  const onSubmit = async (e) => {
    setLoading(true);
    axi.post(`/admin/link/set/${query.oid}`,{...link},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '92ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      width: '25ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));
  const classes = useStyles();

  return (
    <Page title="HASHA Admin | Editing link">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            링크
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            링크 수정 / 열람
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">열람 가능 등급</InputLabel>
              <Select
                name="grade"
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={`${link.grade}`}
                onChange={onChange}
                required
              >
                <MenuItem value='A'>직셀러</MenuItem>
                <MenuItem value='B'>리셀러</MenuItem>
                <MenuItem value='R'>RVIP</MenuItem>
                <MenuItem value='C'>VVIP</MenuItem>
                <MenuItem value='D'>VIP</MenuItem>
                <MenuItem value='E'>구매자</MenuItem>
                <MenuItem value='F'>비구매자</MenuItem>
              </Select>
            </FormControl>
            <TextField id="outlined-basic" name="title" value={`${link.title}`} required onChange={onChange} label="제목" variant="outlined" />
            <TextField id="outlined-basic" name="url" value={`${link.url}`} required onChange={onChange} label="URL" variant="outlined" />
          </form>
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

export default Editlink;

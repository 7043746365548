import { useEffect, useCallback, useState, useRef, createRef } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
// material
import { MenuItem, Button, Container, Stack, Typography, TextField, InputLabel, Select, FormControl } from '@material-ui/core';
// components
import Page from '../components/Page';
import { makeStyles } from '@material-ui/styles';

export default function AddCategory() {
  const navigate = useNavigate();
  const useStyles = makeStyles((theme) => ({
  root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    }
  }));
const classes = useStyles();
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [grade, setGrade] = useState('');
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleChange = (e) => {
    setGrade(e.target.value);
  }
  const onSubmit = () => {
    setLoading(true);
    axi.post('/admin/link/add',{title:title, url:url, grade:grade},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  }
  return (
    <Page title="링크 추가 | HASHA">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            링크 추가 중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <form className={classes.root} noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">열람 가능 등급</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={grade}
                onChange={handleChange}
                required
              >
                <MenuItem value='A'>직셀러</MenuItem>
                <MenuItem value='B'>리셀러</MenuItem>
                <MenuItem value='R'>RVIP</MenuItem>
                <MenuItem value='C'>VVIP</MenuItem>
                <MenuItem value='D'>VIP</MenuItem>
                <MenuItem value='E'>구매자</MenuItem>
                <MenuItem value='F'>비구매자</MenuItem>
              </Select>
            </FormControl>
          </form>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="title" value={`${title}`} required onChange={e => {setTitle(e.target.value)}} label="바로가기 제목" variant="outlined"/>
            <TextField id="outlined-basic" name="url" value={`${url}`} required onChange={e => {setUrl(e.target.value)}} label="URL" variant="outlined"/>
          </form>
        <br />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

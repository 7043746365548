import React, { useEffect, useMemo, useState, useRef, createRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import qs from 'qs';
import toast from 'react-hot-toast';
import Prism from 'prismjs';
import videoPlugin from '@leeonfield/editor-plugin-video';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, FormControlLabel, Switch, Backdrop, CircularProgress, Checkbox} from '@material-ui/core';
// components
import Page from '../components/Page';

const EditNotice = () => {
  const navigate = useNavigate();
  const query = qs.parse(document.location.search, {ignoreQueryPrefix: true});
  const [notice, setNotice] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const editorRef = createRef();
  
  useEffect(() => {
    axi.post(`/admin/board/notice/${query.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setNotice(res.data.data);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
  },[]);

  const onChange = (e) => {
    setNotice({
        ...notice,
        [e.target.name]: e.target.value
    });
  };

  const onSubmit = async (e) => {
    setLoading(true);
    axi.post(`/admin/board/notice/set/${query.oid}`,{...notice, description: editorRef.current.getInstance().getMarkdown()},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '92ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      width: '25ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));
  const classes = useStyles();

  return (
    <Page title="HASHA Admin | Editing Notice">
      {notice==undefined?(
        <Backdrop 
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>):(
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            공지사항
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            공지사항 수정 / 열람
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="name" value={`${notice.tag}`} required onChange={onChange} label="태그" variant="outlined" />
            <TextField id="outlined-basic" name="sequence" value={`${notice.title}`} required onChange={onChange} label="제목" variant="outlined" />
          </form>
          <Typography variant="h5" gutterBottom>
            내용
          </Typography>
          <Editor
            initialValue={`${notice.content}`}
            initialEditType="wysiwyg"
            previewStyle='vertical'
            plugins={[videoPlugin], [colorSyntax, [codeSyntaxHighlight, { highlighter: Prism }]]}
            ref={editorRef}
          />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>
        </Container>
      </Container>)}
    </Page>
  );
}

export default EditNotice;

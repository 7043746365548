import { Navigate, useRoutes, Routes, Route } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import { getAccessToken } from './utils/auth';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import Prolong from './pages/Prolong';
import DashboardApp from './pages/DashboardApp';
import ManageProducts from './pages/ManageProducts';
import AddProducts from './pages/AddProducts';
import AddNotice from './pages/AddNotice'
import EditProducts from './pages/EditProducts';
import ManageNotices from './pages/ManageNotices'
import EditNotice from './pages/EditNotice'
import ManageCategories from './pages/ManageCategories';
import ShopPreferences from './pages/ShopPreferences';
import Features from './pages/Features';
import RegisterByAdmin from './pages/RegisterByAdmin';
import EditUsers from './pages/EditUsers';
import ManageStocks from './pages/ManageStocks';
import ManageStockGroups from './pages/ManageStockGroups';
import ManageLinks from './pages/ManageLinks';
import EditLink from './pages/EditLink'
import AddLinks from './pages/AddLinks';
import EditStockGroup from './pages/EditStockGroup';
import AddStocks from './pages/AddStocks';
import ManageChargeLog from './pages/ManageChargeLog';
import AddCategory from './pages/AddCategory';
import User from './pages/User';
import NotFound from './pages/Page404';
import EditCategory from './pages/EditCampaign';
import ManageBank from './pages/ManageCampaign';
import ManagePurchaseLog from './pages/ManagePurchaseLog';
import ManageAuthLog from './pages/ManageAuthLog';
import EditPurchaseLog from './pages/EditPurchaseLog';
import ManageGiftcodes from './pages/ManageGiftcodes';
import AddGiftcodes from './pages/AddGiftcodes';
import ManageGiftcodeGroups from './pages/ManageGiftcodeGroups';
import EditGiftcodeGroup from './pages/EditGiftcodeGroup';
import EditPopup from './pages/EditPopup';
import AddPopup from './pages/AddPopup';
import ManagePopup from './pages/ManagePopup';
// ----------------------------------------------------------------------

export default function Router() {
  return (
    <Routes>
      <Route path="dashboard" element={<DashboardLayout />}>
        <Route path="/" element={<Navigate to="/dashboard/app" replace />} />
        <PrivateRoute path="app" component={DashboardApp}/>
      </Route>
      <Route path="config" element={<DashboardLayout />}>
        <PrivateRoute path="preferences" component={ShopPreferences} />
        <PrivateRoute path="features" component={Features} />
      </Route>
      <Route path="misc" element={<DashboardLayout />}>
         <PrivateRoute path="auth" component={ManageAuthLog} />
      </Route>
      <Route path="manage" element={<DashboardLayout />}>
        <Route path="products">
          <PrivateRoute path="/" component={ManageProducts} />
          <PrivateRoute path="add" component={AddProducts} />
          <PrivateRoute path="edit" component={EditProducts} />
        </Route>
        <Route path="giftcode">
          <PrivateRoute path="single" component={ManageGiftcodes} />
          <PrivateRoute path="add" component={AddGiftcodes} />
          <PrivateRoute path="group" component={ManageGiftcodeGroups} />
          <PrivateRoute path="group/edit" component={EditGiftcodeGroup} />
        </Route>
        <Route path="popup">
          <PrivateRoute path="/" component={ManagePopup} />
          <PrivateRoute path="add" component={AddPopup} />
          <PrivateRoute path="edit" component={EditPopup} />
        </Route>
        <Route path="stocks">
          <PrivateRoute path="old" component={ManageStocks} />
          <PrivateRoute path="group" component={ManageStockGroups} />
          <PrivateRoute path="add" component={AddStocks} />
          <PrivateRoute path="edit" component={EditStockGroup} />
        </Route>
        <Route path="purchaselog">
          <PrivateRoute path="/" component={ManagePurchaseLog} />
          <PrivateRoute path="edit" component={EditPurchaseLog} />
        </Route>
        <Route path="users">
          <PrivateRoute path="/" component={User} />
          <PrivateRoute path="edit" component={EditUsers} />
          <PrivateRoute path="add" component={RegisterByAdmin} />
        </Route>
        <Route path="notice">
          <PrivateRoute path="/" component={ManageNotices} />
          <PrivateRoute path="add" component={AddNotice} />
          <PrivateRoute path="edit" component={EditNotice} />
        </Route>
        <Route path="category">
          <PrivateRoute path="/" component={ManageCategories} />
          <PrivateRoute path="add" component={AddCategory} />
          <PrivateRoute path="edit" component={EditCategory} />
        </Route>
        <Route path="link">
          <PrivateRoute path="/" component={ManageLinks} />
          <PrivateRoute path="add" component={AddLinks} />
          <PrivateRoute path="edit" component={EditLink} />
        </Route>
        <Route path="campaigns">
          <Route path="/" element={<Navigate to="list" />} />
          <PrivateRoute path="list" component={ManageBank} />
          <PrivateRoute path="edit" component={EditCategory} />
        </Route>
      </Route>
      <Route path="/" element={<LogoOnlyLayout />}>
        <Route path="404" element={<NotFound />} />
        <Route path="auth">
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="prolong" element={<Prolong />} />
        </Route>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

//   return useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { path: '/', element: <Navigate to="/dashboard/app" replace /> },
//         { path: 'app', element: <DashboardApp /> },
//         { path: 'user', element: <User /> },
//         { path: 'manageproducts', element: <ManageProducts /> },
//         { path: 'blog', element: <Blog /> },
//         { path: 'addproducts', element: <AddProducts /> }
//       ]
//     },
//     {
//       path: '/',
//       element: <LogoOnlyLayout />,
//       children: [
//         { path: 'login', element: <Login /> },
//         { path: 'register', element: <Register />},
//         { path: '404', element: <NotFound /> },
//         { path: '/', element: <Navigate to="/dashboard" /> },
//         { path: '*', element: <Navigate to="/404" /> }
//       ]
//     },

//     { path: '*', element: <Navigate to="/404" replace /> }
//   ]);
// }
import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { getAccessToken } from './utils/auth';


const PrivateRoute = ({ component:Component, path }) => {
    const accessToken = getAccessToken();
    if(!accessToken) {
        return <Navigate to="/auth/login" />;
    }
    return <Route path={path} element={<Component />} />
};

export default PrivateRoute;
import * as Yup from 'yup';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import { useFormik, Form, FormikProvider } from 'formik';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, Tooltip } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { registerWithInfo } from 'src/utils/auth';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    id: Yup.string()
      .min(3, '너무 짧습니다')
      .max(50, '너무 깁니다')
      .required('아이디를 입력해주세요'),
    pwd: Yup.string()
      .min(3, '너무 짧습니다')
      .max(50, '너무 깁니다')
      .required('패스워드를 입력해주세요'),
    name: Yup.string()
    .min(2, '너무 짧습니다')
    .max(20, '너무 깁니다')
    .required('닉네임을 입력해주세요'),
    code: Yup.string().required('라이센스 코드를 입력해주세요.'),
    group: Yup.string()
    .min(3, '너무 짧습니다')
    .max(20, '너무 깁니다')
    .required('상점 ID를 입력해주세요.'),
  });

  const formik = useFormik({
    initialValues: {
      id: '',
      pwd: '',
      name: '',
      code: '',
      group: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async () => {
      if(await registerWithInfo({ ...values })) navigate('/dashboard/app', { replace: true });
    }
  });

  const { errors, touched, values, handleSubmit, isSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="아이디"
              {...getFieldProps('id')}
              error={Boolean(touched.id && errors.id)}
              helperText={touched.id && errors.id}
            />
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="패스워드"
              {...getFieldProps('pwd')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.pwd && errors.pwd)}
              helperText={touched.pwd && errors.pwd}
            />
          </Stack>

          <TextField
            fullWidth
            label="닉네임"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            label="라이센스"
            {...getFieldProps('code')}
            error={Boolean(touched.code && errors.code)}
            helperText={touched.code && errors.code}
          />
          <Tooltip arrow title="상점ID.HASHA.io 로 기본도메인 제공됨. 알파벳과 숫자만 입력 가능">
            <TextField
              fullWidth
              label="상점 ID"
              {...getFieldProps('group')}
              error={Boolean(touched.group && errors.group)}
              helperText={touched.group && errors.group}
            />
          </Tooltip>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            가입하기
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import product from '@iconify/icons-eva/shopping-cart-fill'
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import globeFill from '@iconify/icons-eva/settings-2-fill';
import editFill from '@iconify/icons-eva/color-picker-fill';
import chargelog from '@iconify/icons-eva/credit-card-fill'
import notice from '@iconify/icons-eva/clipboard-fill'
import link from '@iconify/icons-eva/link-2-fill'
import bank from '@iconify/icons-eva/swap-fill'

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: '대시보드',
    path: '/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: '캠페인관리',
    path: '/manage/campaigns',
    icon: getIcon(editFill)
  },
];

export default sidebarConfig;

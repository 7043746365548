import { Icon } from '@iconify/react';
import dollarCircleFilled from '@iconify/icons-ant-design/dollar-circle-filled';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
// utils
import { fNumber } from '../../../utils/formatNumber';

import { useEffect, useState } from 'react';


import moment from 'moment';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.warning.dark, 0)} 0%, ${alpha(
    theme.palette.warning.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

const TOTAL = 1352800;


export default function AppWeeklyProfit(data) {

  const [nowTime, setNowTime] = useState('');

  useEffect(() => {
    // 매 초마다 초단위 시간 가져오기
    const timer = setInterval(() => {
      const now = moment();
      setNowTime(now.format('HH시 mm분 ss초'));
    }, 1000);
    return () => clearInterval(timer);
  }
  ,[])


  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon={dollarCircleFilled} width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h3">{nowTime}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        현재 시간
      </Typography>
    </RootStyle>
  );
}

import React, { useEffect, useCallback, useState, useRef, createRef, useMemo } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, Stack, Typography, TextField, InputAdornment, Backdrop, CircularProgress } from '@material-ui/core';
// components
import Page from '../components/Page';
import ColorManyPicker from 'src/components/ColorManyPicker';
//
import toast from 'react-hot-toast';

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const navigate = useNavigate();
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axi.post('/admin/config',{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setConfig(res.data.data);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
  },[]);

  const onChange = (e) => {
    setConfig({
        ...config,
        [e.target.name]: e.target.value
    });
  };

  const onSubmit = async (e) => {
    setLoading(true);
    axi.post('/admin/setconfig',{...config},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));

  const classes = useStyles();

  return (
    <Page title="상점 설정 | HASHA">
      <Backdrop 
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            외관 설정 변경 중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            외관 색상 설정
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" label="하이라이트 색상" value={config.highlightColor} required onChange={onChange} name="highlightColor" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="기본 회색" value={config.defaultGray} required onChange={onChange} name="defaultGray" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="어두운 회색" value={config.darkGray} required onChange={onChange} name="darkGray" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="밝은 회색" value={config.lightGray} required onChange={onChange} name="lightGray" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="기본 텍스트" value={config.defaultTextColor} required onChange={onChange} name="defaultTextColor" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="보조 텍스트" value={config.descGray} required onChange={onChange} name="descGray" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="보조 텍스트2" value={config.headerTextColor} required onChange={onChange} name="headerTextColor" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="상단 바 배경" value={config.navHeaderBg} required onChange={onChange} name="navHeaderBg" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="상단 바 블럭" value={config.navHeaderHolder} required onChange={onChange} name="navHeaderHolder" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="기본 흰색" value={config.defaultWhite} required onChange={onChange} name="defaultWhite" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="그라데이션 시작" value={config.gradStart} required onChange={onChange} name="gradStart" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
            <TextField id="outlined-basic" label="그라데이션 끝" value={config.gradEnd} required onChange={onChange} name="gradEnd" variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">#</InputAdornment>,
            }} />
          </form>
        <br />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

import { cookie } from './cookie'
import toast, { Toaster } from 'react-hot-toast';
import { axi } from './api';
import { fetchData, saveData, saveMyExpire } from './user';
import { errMessages } from './api';
import jwt_decode from 'jwt-decode';

export const getAccessToken = () => {
  return cookie.get("ACCESS_TOKEN");
};

export const clearTokens = () => {
  cookie.remove("ACCESS_TOKEN", { path: '/' });
};

export const loginWithInfo = async (data) => {
  const authtoast = toast.loading('인증 서버와 통신중...');
  try{
    const res = await axi.post('/auth/login', data);
    if(res.status === 200) {
      cookie.set("ACCESS_TOKEN", res.data.token, { path: '/' });
      saveData(jwt_decode(res.data.token));
      saveMyExpire(res.data.expire)
      toast.success('로그인 성공', { id: authtoast });
      return true;
    }
    else {
      toast.error(res.data.msg, { id: authtoast });
      return false;
    }
  }
  catch (err){
    console.log(err);
    return false;
  };
}

export const registerWithInfo = async (data) => {
  const authtoast = toast.loading('인증 서버와 통신중...');
  try{
    const res = await axi.post('/admin/license/register', data);
    if(res.data.code === 200) {
      toast.success('회원가입 성공', { id: authtoast });
      return true;
    }
    else {
      toast.error(res.data.msg, { id: authtoast });
      return false;
    }
  }
  catch (err){
    console.log(err);
    return false;
  };
}

export const prolong = async (data) => {
  const authtoast = toast.loading('인증 서버와 통신중...');
  try{
    const res = await axi.post('/admin/license/prolong', data);
    if(res.data.code === 200) {
      toast.success('연장 성공!', { id: authtoast });
      return true;
    }
    else {
      toast.error(res.data.msg, { id: authtoast });
      return false;
    }
  }
  catch (err){
    console.log(err);
    return false;
  };
}
import { useEffect, useCallback, useState, useRef, createRef } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast'
import Prism from 'prismjs';
import videoPlugin from '@leeonfield/editor-plugin-video';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { Grid, Button, Container, Stack, Typography, TextField } from '@material-ui/core';
// components
import Page from '../components/Page';
import { makeStyles } from '@material-ui/styles';

export default function AddNotice() {
    const navigate = useNavigate();
    const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
        }
    }));
    const classes = useStyles();
    const [tag, setName] = useState('');
    const [title, setSequence] = useState('');
    const [loading, setLoading] = useState(false);
    const editorRef = createRef();
    const onSubmit = () => {
        setLoading(true);
        axi.post('/admin/board/notice/add',{tag:tag, title:title, content:editorRef.current.getInstance().getMarkdown()},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
        .then((res)=>{
        if(res.data.code === 200){
            toast.success("추가 완료!");
        }
        if(res.data.code === 401){
            toast.error(res.data.msg);
            navigate('/auth/login',{ replace:true });
        }
        setLoading(false);
        }).catch((err)=>{
        console.log(err);
        setLoading(false);
        });
    }
    return (
        <Page title="공지사항 추가 | HASHA">
        <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                공지사항 추가 중
            </Typography>
            </Stack>
            <Container maxWidth="xl">
            <form className={classes.root} noValidate autoComplete="off">
                <TextField id="outlined-basic" name="dGrade" value={`${tag}`} required onChange={e => {setName(e.target.value)}} label="태그(말머리)" variant="outlined"/>
                <TextField id="outlined-basic" name="cGrade" value={`${title}`} required onChange={e => {setSequence(e.target.value)}} label="제목" variant="outlined"/>
            </form>
            <Editor
                initialEditType="wysiwyg"
                previewStyle='vertical'
                plugins={[videoPlugin], [colorSyntax, [codeSyntaxHighlight, { highlighter: Prism }]]}
                ref={editorRef}
            />
            <br />
            <Button
            type="submit"
            size="large"
            variant="contained"
            onClick={onSubmit}
            >
            등록하기
            </Button>
            </Container>
        </Container>
        </Page>
    );
}

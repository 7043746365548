import React, { useEffect, useMemo, useState, useRef, createRef } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import toast from 'react-hot-toast';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, FormControlLabel, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox, Switch } from '@material-ui/core';
// components
import Page from '../components/Page';
import ColorManyPicker from 'src/components/ColorManyPicker';

// ----------------------------------------------------------------------

export default function PromoteRate() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState({
    id: '',
    pwd: '',
    cash: 0,
    point: 0,
    userType: 'F',
    verified: false,
    memo: '',
    name: ''
  })

  const onChange = (e) => {
    setUser({
        ...user,
        [e.target.name]: e.target.value
    }); 
  };

  const onSwitchChange = (e) => {
    setUser({...user, [e.target.name]: e.target.checked});
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onSubmit = async (e) => {
    setLoading(false);
    const reqtoast = toast.loading('서버와 통신중입니다...')
    axi.post('/admin/user/add',{...user},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      } else {
        toast.error(res.data.msg);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(true);
    }).catch((err)=>{
      console.log(err);
      setLoading(true);
    });
  };


  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));

  const classes = useStyles();

  return (
    <Page title="회원 추가 | HASHA">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            신규 회원 추가중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            회원 정보
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <FormControl className={classes.formControl}>
              <InputLabel id="demo-controlled-open-select-label">유저등급</InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                name="userType"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={`${user.userType}`}
                onChange={onChange}
                required
              >
                <MenuItem value={'F'}>비구매자</MenuItem>
                <MenuItem value={'E'}>구매자</MenuItem>
                <MenuItem value={'D'}>VIP</MenuItem>
                <MenuItem value={'C'}>VVIP</MenuItem>
                <MenuItem value={'R'}>RVIP</MenuItem>
                <MenuItem value={'B'}>리셀러</MenuItem>
                <MenuItem value={'A'}>직셀러</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
                control={<Switch name="verified" checked={user.verified} onChange={onSwitchChange} />}
                label="유저 인증 여부"
            />
            <TextField id="outlined-basic" name="id" value={`${user.id}`} required onChange={onChange} label="유저 ID" variant="outlined" />
            <TextField id="outlined-basic" name="pwd" value={`${user.pwd}`} required onChange={onChange} label="유저 PW" variant="outlined" />
            <TextField id="outlined-basic" name="name" value={`${user.name}`} required onChange={onChange} label="입금자명" variant="outlined" />
            <TextField id="outlined-basic" name="cash" value={`${user.cash}`} required onChange={onChange} label="유저 보유 금액" variant="outlined" />
            <TextField id="outlined-basic" name="point" value={`${user.point}`} required onChange={onChange} label="유저 보유 포인트" variant="outlined" />
            <TextField
              id="outlined-multiline-static"
              label="유저 메모 (관리자 열람용)"
              name="memo"
              value={`${user.memo}`}
              multiline
              rows={4}
              defaultValue=""
              variant="outlined"
              onChange={onChange}
            />
          </form>
        <br />
        {loading?(<Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>):(<Button
          type="submit"
          size="large"
          variant="contained"
          disabled
        >
        등록중...
        </Button>)}
        </Container>
      </Container>
    </Page>
  );
}

import React, { useEffect, useState, useMemo } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import toast from 'react-hot-toast';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, Stack, Typography, TextField, FormControlLabel, Switch, InputAdornment, Backdrop, CircularProgress } from '@material-ui/core';
// components
import Page from '../components/Page';

export default function EcommerceShop() {
  const navigate = useNavigate();
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    axi.post('/admin/config',{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setConfig(res.data.data);
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
  },[]);
  
  const onChange = e => {
    setConfig({...config, [e.target.name]: e.target.value})
  };

  const onSwitchChange = (e) => {
    setConfig({...config, [e.target.name]: e.target.checked});
  };

  const onSubmit = async (e) => {
    if(config.pushBulletKey){
      if(config.pushBulletKey.length != 34) {
        toast.error('PushBullet API Key 형식이 옳지 않습니다.');
        return;
      }
    }
    if(config.solapiKey || config.solapiSecret || config.smsFrom) {
      if(!config.solapiKey || !config.solapiSecret || !config.smsFrom) {
        toast.error('SMS 설정을 사용하지 않으시려면, 완전히 빈 칸으로 설정해주세요.');
        return;
      }}
    setLoading(true);
    axi.post('/admin/setconfig',{...config},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        toast.success("적용 완료!");
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
      setLoading(false);
    }).catch((err)=>{
      console.log(err);
      setLoading(false);
    });
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
    account: {
      width: '78ch',
    }
  }));

  const classes = useStyles();

  return (
    <Page title="상점 설정 | HASHA">
      <Backdrop 
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            상점 설정 변경 중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            상점 전역 설정
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" name="shopName" value={`${config.shopName}`} required onChange={onChange} label="상점 이름" variant="outlined"/>
            <TextField id="outlined-basic" name="authBg" value={`${config.authBg}`} required onChange={onChange} label="로그인 배경 사진 URL" variant="outlined"/>
            <TextField id="outlined-basic" name="sidePic" value={`${config.sidePic}`} required onChange={onChange} label="사이드바 사진 URL (1:1R)" variant="outlined"/>
            <TextField id="outlined-basic" name="bannerImg" value={`${config.bannerImg}`} onChange={onChange} label="배너 사진 (사용 하지 않을 시 공란)" variant="outlined"/>
            <TextField id="outlined-basic" name="joinBonus" value={`${config.joinBonus}`} required onChange={onChange} label="가입 기념 충전금" variant="outlined"/>
            <FormControlLabel
              control={<Switch name="needVerify" checked={config.needVerify} onChange={onSwitchChange} />}
              label="충전시 인증 필요 여부"
            />
            <FormControlLabel
              control={<Switch name="needVerifyCulture" checked={config.needVerifyCulture} onChange={onSwitchChange} />}
              label="컬쳐랜드 충전시 인증 필요 여부"
            />
            <FormControlLabel
              control={<Switch name="restrictRegist" checked={config.restrictRegist} onChange={onSwitchChange} />}
              label="상점 신규 가입 제한"
            />
            <FormControlLabel
              control={<Switch name="noSubAcc" checked={config.noSubAcc} onChange={onSwitchChange} />}
              label="중복 IP 가입 제한"
            />
            <Typography variant="h5" gutterBottom>
              계좌 충전 설정
            </Typography>
            <TextField className={classes.account} id="outlined-basic" name="accountInfo" value={`${config.accountInfo}`} onChange={onChange} label="계좌정보" variant="outlined" fullWidth/>
            <br />
            <TextField className={classes.account} id="outlined-basic" name="pushBulletKey" value={`${config.pushBulletKey}`} onChange={onChange} label="PushBullet API-KEY" variant="outlined" fullWidth/>
            <br />
            <TextField id="outlined-basic" name="minimum" value={`${config.minimum}`} onChange={onChange} label="최소 충전 금액" variant="outlined" />
            <TextField id="outlined-basic" name="pointMinimum" value={`${config.pointMinimum}`} onChange={onChange} label="포인트 적립 최저 기준" variant="outlined" />
            <TextField id="outlined-basic" name="pointPercent" value={`${config.pointPercent}`} onChange={onChange} label="포인트 적립 비율" variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} />
            <TextField id="outlined-basic" name="chargeFee" value={`${config.chargeFee}`} onChange={onChange} label="충전 수수료" variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} />
            <Typography variant="h5" gutterBottom>
              컬쳐랜드 충전 설정
            </Typography>
            <TextField id="outlined-basic" name="cultureId" value={`${config.cultureId}`} onChange={onChange} label="컬쳐랜드 ID" variant="outlined"/>
            <TextField id="outlined-basic" name="culturePw" value={`${config.culturePw}`} onChange={onChange} label="컬쳐랜드 PW" variant="outlined"/>
            <br />
            <TextField id="outlined-basic" name="userFailCount" value={`${config.userFailCount}`} onChange={onChange} label="최대 실패 횟수 (초과시 유저 차단)" variant="outlined" />
            <TextField id="outlined-basic" name="shopFailCount" value={`${config.shopFailCount}`} onChange={onChange} label="최대 실패 횟수 (초과시 문상충전 비활)" variant="outlined" />
            <TextField id="outlined-basic" name="pointCultureMinimum" value={`${config.pointCultureMinimum}`} onChange={onChange} label="포인트 적립 최저 기준" variant="outlined" />
            <TextField id="outlined-basic" name="pointCulturePercent" value={`${config.pointCulturePercent}`} onChange={onChange} label="포인트 적립 비율" variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} />
            <TextField id="outlined-basic" name="cultureFee" value={`${config.cultureFee}`} onChange={onChange} label="문상 충전 수수료" variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }} />
            <Typography variant="h5" gutterBottom>
              유저 승급 조건
            </Typography>
              <form className={classes.root} noValidate autoComplete="off">
              <TextField id="outlined-basic" name="dGrade" value={`${config.dGrade}`} required onChange={onChange} label="VIP 승급 조건 금액" variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">원</InputAdornment>,
              }} />
              <TextField id="outlined-basic" name="cGrade" value={`${config.cGrade}`} required onChange={onChange} label="VVIP 승급 조건 금액" variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">원</InputAdornment>,
              }} />
              <TextField id="outlined-basic" name="rGrade" value={`${config.rGrade}`} required onChange={onChange} label="RVIP 승급 조건 금액" variant="outlined"
              InputProps={{
                endAdornment: <InputAdornment position="end">원</InputAdornment>,
              }} />
            </form>
            <Typography variant="h5" gutterBottom>
              SMS / 채널톡 설정
            </Typography>
              <TextField id="outlined-basic" name="channelIo" value={`${config.channelIo}`} onChange={onChange} label="채널IO 플러그인 키" variant="outlined" />
              <TextField id="outlined-basic" name="solapiKey" value={`${config.solapiKey}`} onChange={onChange} label="SOLAPI 키" variant="outlined" />
              <TextField id="outlined-basic" name="solapiSecret" value={`${config.solapiSecret}`} onChange={onChange} label="SOLAPI 시크릿" variant="outlined" />
              <TextField id="outlined-basic" name="smsFrom" value={`${config.smsFrom}`} onChange={onChange} label="SMS 발신번호" variant="outlined" />
            <Typography variant="h5" gutterBottom>
              푸터 설정
            </Typography>
              <TextField id="outlined-basic" name="footer1" value={`${config.footer1}`} onChange={onChange} label="푸터 1번줄 문구" variant="outlined" />
              <TextField id="outlined-basic" name="footer2" value={`${config.footer2}`} onChange={onChange} label="푸터 2번줄 문구" variant="outlined" />
            <Typography variant="h5" gutterBottom>
              Misc 설정
            </Typography>
            <FormControlLabel
                control={<Switch name="purchaseLog" checked={`${config.purchaseLog}`} onChange={onSwitchChange} />}
                label="구매 로그 사용 여부"
            />
            <FormControlLabel
                control={<Switch name="review" checked={`${config.review}`} onChange={onSwitchChange} />}
                label="구매 후기 사용 여부"
            /><br />
            <TextField id="outlined-basic" name="discordWebhook" value={`${config.discordWebhook}`} onChange={onChange} label="디스코드 Webhook URI" variant="outlined" />
            <TextField id="outlined-basic" name="adminDiscordWebhook" value={`${config.adminDiscordWebhook}`} onChange={onChange} label="관리자 디스코드 Webhook URI" variant="outlined" />
            <TextField id="outlined-basic" name="musicUri" value={`${config.musicUri}`} onChange={onChange} label="음악 URI(sndup.net)" variant="outlined" />
            <TextField id="outlined-basic" name="pointTransferMinimum" value={`${config.pointTransferMinimum}`} onChange={onChange} label="포인트 환급 최소 금액" variant="outlined" />
          </form>
        <br />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import approve from '@iconify/icons-eva/checkmark-circle-2-fill'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@material-ui/core';
import { axi, errMessages } from 'src/utils/api';
import { getAccessToken } from '../../../utils/auth'
import toast from 'react-hot-toast'

export default function UserMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const getRemoveUrl = () => {
    if(location.pathname.includes('campaigns')) return `/admin/campaigns/remove/${props.oid}`;
    else if(location.pathname.includes('products')) return `/admin/product/remove/${props.oid}`;
    else if(location.pathname.includes('users')) return `/admin/user/remove/${props.oid}`;
    else if(location.pathname.includes('notice')) return `/admin/board/notice/remove/${props.oid}`;
    else if(location.pathname.includes('old')) return '#';
    else if(location.pathname.includes('link')) return `/admin/link/remove/${props.oid}`;
    else if(location.pathname.includes('log')) return `/admin/removepurchaselog/${props.oid}`;
    else if(location.pathname.includes('giftcode/group')) return `/admin/giftcode/removegroup/${props.oid}`;
    else if(location.pathname.includes('giftcode')) return `/admin/giftcode/remove/${props.oid}`;
    else if(location.pathname.includes('popup')) return `/admin/board/popup/remove/${props.oid}`;
    else return `/admin/code/removegroup/${props.oid}`;
  }
  const getEditUrl = () => {
    if(location.pathname.includes('campaigns')) return `/manage/campaigns/edit?oid=${props.oid}`;
    else if(location.pathname.includes('products')) return `/manage/products/edit?oid=${props.oid}`;
    else if(location.pathname.includes('notice')) return `/manage/notice/edit?oid=${props.oid}`;
    else if(location.pathname.includes('giftcode/group')) return `/manages/giftcode/group/edit?oid=${props.oid}`;
    else if(location.pathname.includes('group')) return `/manage/stocks/edit?oid=${props.oid}`;
    else if(location.pathname.includes('link')) return `/manage/link/edit?oid=${props.oid}`;
    else if(location.pathname.includes('log')) return `/manage/purchaselog/edit?oid=${props.oid}`;
    else if(location.pathname.includes('popup')) return `/manage/popup/edit?oid=${props.oid}`;
    else return `/manage/users/edit?oid=${props.oid}`;
  }
  const removeAction = async () => {
    const t = toast.loading('삭제 중...');
    try{
      const res = await axi.post(getRemoveUrl(),{},{headers:{Authorization : `Bearer ${getAccessToken()}`}});
      if(res.data.code === 200){
        toast.success("삭제 성공!", {id:t});
      }
      else if(res.data.code === 401){
        toast.error(res.data.msg, {id:t});
        navigate('/auth/login',{ replace:true });
      }
      else {
        toast.error(res.data.msg, {id:t});
      }
    } catch (err) {
      console.log(err);
      toast.error("삭제 실패!", {id:t});
    }
    if(props.event) props.event();
  }
  const bankApprove = async () => {
    const t = toast.loading('승인 중...');
    try{
      const res = await axi.post(`/admin/charge/approve/${props.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}});
      if(res.data.code === 200){
        toast.success("승인 성공!", {id:t});
      }
      else if(res.data.code === 401){
        toast.error(res.data.msg, {id:t});
        navigate('/auth/login',{ replace:true });
      }
      else {
        toast.error(res.data.msg, {id:t});
      }
    } catch (err) {
      console.log(err);
      toast.error("승인 실패!", {id:t});
    }
    props.event();
  }
  const bankDiscard = async () => {
    const t = toast.loading('삭제 중...');
    try{
      const res = await axi.post(`/admin/charge/discard/${props.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}});
      if(res.data.code === 200){
        toast.success("삭제 성공!", {id:t});
      }
      else if(res.data.code === 401){
        toast.error(res.data.msg, {id:t});
        navigate('/auth/login',{ replace:true });
      }
      else {
        toast.error(res.data.msg, {id:t});
      }
    } catch (err) {
      console.log(err);
      toast.error("삭제 실패!", {id:t});
    }
    props.event();
  }
  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
      {location.pathname.includes('bank')?
        <>
          <MenuItem onClick={bankApprove} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={approve} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary='승인하기' primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem onClick={bankDiscard} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="삭제하기" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </> : <>
          <MenuItem component={RouterLink} to={getEditUrl()} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary={location.pathname.includes('bank')?'승인하기':'수정하기'} primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
          <MenuItem onClick={removeAction} sx={{ color: 'text.secondary' }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="삭제하기" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        </>
      }
      </Menu>
    </>
  );
}
import React, { useEffect, useCallback, useState, useRef, createRef } from 'react';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// Editor
import toast from 'react-hot-toast';
import Prism from 'prismjs';
import videoPlugin from '@leeonfield/editor-plugin-video';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Backdrop, CircularProgress } from '@material-ui/core';
// components
import Page from '../components/Page';
import productall from '../_mocks_/productall';
//

// ----------------------------------------------------------------------

export default function EcommerceShop() {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [Secopen, setSecOpen] = React.useState(false);
  const [inputs, setInputs] = React.useState({
    name: '',
    motherObject: '',
    products: [],
    priceOption: '',
    keysNonFiltered: '',
    cost: 0,
  })

  useEffect(() => {
    axi.post('/admin/product/onlylist',{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setInputs({
          ...inputs,
          products: res.data.data,
        })
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    })
    .catch((err)=>{
      console.log(err);
    });
  },[]);

  const {
    name, motherObject, priceOption, keysNonFiltered, cost
  } = inputs;

  const onChange = (e) => {
    setInputs({
        ...inputs,
        [e.target.name]: e.target.value
    });
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleSecClose = () => {
    setSecOpen(false);
  };
  const handleSecOpen = () => {
    setSecOpen(true);
  };
  const onSubmit = async (e) => {
    if ( motherObject === '' || keysNonFiltered === '' || priceOption === '') {
      toast.error("모든 입력 란을 채워주세요.");
      return;
    }
    else {
      let keys = keysNonFiltered.split('\n');
      for ( var idx = keys.length-1 ; idx > -1 ; idx-- ) {
        if ( keys[idx] === "" ) {
          toast.error("입고 값에 빈 값이 존재합니다.");
          return;
        }
      }
      try{
        axi.post(`/admin/code/add`,{
          name: name,
          ProductId: motherObject, 
          priceOpt: priceOption,
          cost: cost,
          keys: keys,
        },{headers:{Authorization : `Bearer ${getAccessToken()}`}})
        .then((res)=>{
          if(res.data.code === 200){
            toast.success("적용 완료!");
          }
          if(res.data.code === 401){
            toast.error(res.data.msg);
            navigate('/auth/login',{ replace:true });
          }
        }).catch((err)=>{
          console.log(err);
        });
      } catch (err) {
        console.log(err);
        toast.error("알 수 없는 오류입니다. 올바르게 입력하고 다시 시도해주세요.");
      }
    }
  };
  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '92ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      width: '25ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));

  const classes = useStyles();

  return (
    <Page title="Dashboard: Add Products | HASHA">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            재고 관리 중
          </Typography>
        </Stack>
        <Container maxWidth="xl">
          <Typography variant="h5" gutterBottom>
            재고 추가
          </Typography>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">제품 선택</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              name="motherObject"
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={motherObject}
              onChange={onChange}
              required
            >
              {inputs.products?.map((data) => {
                return (
                  <MenuItem value={data._id}>{data.name}</MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-controlled-open-select-label">옵션선택</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              name="priceOption"
              open={Secopen}
              onClose={handleSecClose}
              onOpen={handleSecOpen}
              value={priceOption}
              onChange={onChange}
              required
            >
              {inputs.motherObject!=''?(
                inputs.products.find(element => element._id == motherObject).priceOptions.map((data) => {
                  return (
                    <MenuItem value={data._id}>{data.optionName}</MenuItem>
                  );
                })
              ):(
                <MenuItem value="">제품을 먼저 선택해주세요</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="outlined-basic" name="cost" value={cost} required onChange={onChange} label="입고가" variant="outlined" />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField id="outlined-basic" name="name" value={name} required onChange={onChange} label="재고 그룹 이름" variant="outlined" />
          </FormControl>
          <Typography variant="h5" gutterBottom>
            입고 리스트 (엔터로 구분)
          </Typography>
          <form className={classes.root} noValidate autoComplete="off">
            <TextField
                id="outlined-multiline-static"
                label="Keys"
                name="keysNonFiltered"
                value={keysNonFiltered}
                onChange={onChange}
                multiline
                rows={10}
                defaultValue=""
                variant="outlined"
              />
          </form>
        <br />
        <Button
          type="submit"
          size="large"
          variant="contained"
          onClick={onSubmit}
        >
        등록하기
        </Button>
        </Container>
      </Container>
    </Page>
  );
}

import React, { useEffect, useMemo, useState, useRef, createRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { axi, errMessages } from '../utils/api'
import { getAccessToken } from '../utils/auth'
import qs from 'qs';
import toast from 'react-hot-toast';
import Prism from 'prismjs';
import videoPlugin from '@leeonfield/editor-plugin-video';
import 'prismjs/themes/prism.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css';
import codeSyntaxHighlight from '@toast-ui/editor-plugin-code-syntax-highlight';
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
// material
import { makeStyles } from '@material-ui/styles';
import { Button, Container, FormControlLabel, Stack, Typography, TextField, MenuItem, FormControl, InputLabel, Select, Checkbox } from '@material-ui/core';
// components
import { grade, giftTypes } from '../utils/user'

import Page from '../components/Page';

const EditGiftcodeGroup = () => {
  const navigate = useNavigate();
  const query = qs.parse(document.location.search, {ignoreQueryPrefix: true});
  const [open, setOpen] = React.useState(false);
  const [Secopen, setSecOpen] = React.useState(false);
  const [codegroup, setCodegroup] = useState({});
  const [codetxt, setCodetxt] = useState('');
  const [loading, setLoading] = useState(false);
  console.log(codegroup)
  useEffect(() => {
    axi.post(`/admin/giftcode/group/${query.oid}`,{},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
    .then((res)=>{
      if(res.data.code === 200){
        setCodegroup(res.data.data);
        setCodetxt(res.data.codes.map(e=>e.key + '\n').join('').trim());
      }
      if(res.data.code === 401){
        toast.error(res.data.msg);
        navigate('/auth/login',{ replace:true });
      }
    }).catch((err)=>{
      console.log(err);
    });
  },[]);

  const onChange = (e) => {
    setCodegroup({
        ...codegroup,
        [e.target.name]: e.target.value
    });
  };
  const onCodeChange = (e) => {
    setCodetxt(e.target.value);
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleSecClose = () => {
    setSecOpen(false);
  };
  const handleSecOpen = () => {
    setSecOpen(true);
  };
  // const onSubmit = async (e) => {
  //   setLoading(true);
  //   const keys = codetxt.split('\n');
  //   axi.post(`/admin/code/group/set/${query.oid}`,{group:codegroup, codes:keys},{headers:{Authorization : `Bearer ${getAccessToken()}`}})
  //   .then((res)=>{
  //     if(res.data.code === 200){
  //       toast.success("적용 완료!");
  //     }
  //     if(res.data.code === 401){
  //       toast.error(res.data.msg);
  //       navigate('/auth/login',{ replace:true });
  //     }
  //     setLoading(false);
  //   }).catch((err)=>{
  //     console.log(err);
  //     setLoading(false);
  //   });
  // };
    const onSubmit = async (e) => {
      toast.info("열람만 가능합니다.");
    };  

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '92ch',
      },
    },
    rootOpt: {
      '& > *': {
        margin: theme.spacing(1),
        width: '18ch',
      },
    },
    button: {
      display: 'block',
      marginTop: theme.spacing(2),
      width: '25ch',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: '25ch',
    },
  }));
  const classes = useStyles();

  return (
    <Page title="HASHA Admin | Editing Notice">
      <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            기프트 코드 그룹
          </Typography>
        </Stack>
        <Container maxWidth="xl">
            <Typography variant="h5" gutterBottom>
                기프트 코드 그룹 열람
            </Typography>
            <FormControl className={classes.formControl}>
                <TextField id="outlined-basic" name="minGrade" value={`${grade[codegroup.minGrade]}`} required onChange={onChange} label="최소 적용 등급" variant="outlined" />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField id="outlined-basic" name="giftType" value={`${giftTypes[codegroup.giftType]}`} required onChange={onChange} label="발행 종류" variant="outlined" />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField id="outlined-basic" name="amount" value={`${codegroup.amount}`} required onChange={onChange} label="금액" variant="outlined" />
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField id="outlined-basic" name="expiryDate" value={`${codegroup.expiryDate}`} required onChange={onChange} label="만료일" variant="outlined" />
            </FormControl>
            <form className={classes.root} noValidate autoComplete="off">
            <TextField id="outlined-basic" value={codetxt} onChange={onCodeChange} required multiline label="코드 목록" variant="outlined" />
            </form>
            {/*{codes?.map((data) => {
                    return (
                    <FormControl className={classes.formControl}>
                        <TextField id="outlined-basic" name="name" value={`${data.code}`} required onChange={onChange} label="코드" variant="outlined" />
                    </FormControl>
                    )
                })}*/}
            <Button
            type="submit"
            size="large"
            variant="contained"
            onClick={onSubmit}
            disabled
            >
            수정 불가
            </Button>
        </Container>
      </Container>
    </Page>
  );
}

export default EditGiftcodeGroup;
